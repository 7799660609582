@import "./material/button.styl"
@import "./material/table.styl"
@import "./material/expansion-panel.styl"
@import "./material/form-field.styl"
@import "./material/dialog.styl"
@import "./material/spinner.styl"
@import "./material/popover.styl"
@import "./material/tooltip.styl"
@import "./material/tabs.styl"
@import "./material/button-toggle.styl"
@import "./material/datepicker.styl"
@import "./material/checkbox.styl"
@import "./material/slide-toggle.styl"
@import "./material/radio-button.styl"
@import "./material/icon.styl"
@import "./material/menu.styl"
@import "./material/stepper.styl"
@import "./material/mat-select.styl"
@import "./material/mat-menu.styl"
@import "./material/mat-chip.styl"

.mat-form-field-ripple
  opacity 0 !important

