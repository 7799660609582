/* KKM Expansion Panel */
.mat-expansion-panel.kkm-expansion-panel
  margin-bottom 0.8rem
  box-shadow 0 1px 8px rgba(0, 0, 0, 0.04)
  border-radius 2px !important

  .mat-expansion-panel-header,
  .mat-expansion-panel-content .mat-expansion-panel-body
    padding 0

  &.mat-expansion-panel-spacing
    margin-top 0
    margin-bottom 1rem

.kkm-expansion-panel-arrow
  display inline-block
  width 0.8rem
  height 0.8rem
  opacity 0.7
  vertical-align middle
  border 2px solid var(--fg-secondary-color)
  border-left none
  border-top none
  border-radius 2px
  transform rotate(-45deg)
  transition transform ease-in 0.1s

  &.expanded
    transform rotate(45deg)
