mat-form-field.kkm-form-field.kkm-mat-chip-form-field.mat-form-field
  &.compact
    &.mat-form-field-appearance-fill
      .mat-form-field-flex
        padding 0.4rem 0.8rem 0.4rem 1.6rem !important;

.kkm-mat-chip-form-field
  .mat-form-field-flex
    height auto !important
    max-height none !important
    min-height 4rem

  input.mat-chip-input
    overflow hidden
    text-overflow ellipsis
    flex 1 0 2.2rem
    width 100%
    margin 0 -0.4rem 0 0 !important

.mat-standard-chip
  padding 0.6rem 0.8rem !important
  margin 0.2rem !important
  background var(--gray-color)
  border-radius 2px !important
  font var(--fw-regular) var(--fs-h3) var(--font-family)
  color var(--black-color)

  .mat-icon.mat-chip-remove
    color var(--gray1-color)
    width 1rem !important
    height 1rem !important

