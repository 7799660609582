.mat-button-toggle-group
  border-radius 2px !important
  height: 4rem
  display flex
  align-items center
  width 100%
  .mat-button-toggle
    flex 1 1 105px
    background-color var(--white-color)
    height 4rem
    .mat-button-toggle-button
      border 1px solid var(--border-color)
      border-right none
    &:last-child
      .mat-button-toggle-button
        border-right 1px solid var(--border-color)
    .mat-button-toggle-button
      height 4rem
      .mat-button-toggle-label-content
        font-size var(--fs-h4)
        line-height var(--lh-h4)
    .mat-button-toggle-focus-overlay
      border-bottom 0 !important
    &.mat-button-toggle-checked
      .mat-button-toggle-button
        border 2px solid var(--brand-color)
        background var(--white-color)
        color var(--brand-color)
        font-weight var(--fw-medium)
    &.mat-button-toggle-checked + .mat-button-toggle
      .mat-button-toggle-button
        border-left transparent
