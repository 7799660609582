.mat-radio-group
  @extend .body_text

.mat-radio-button
  .mat-radio-container
    width 1.8rem
    height 1.8rem
    margin-right 1.2rem
  .mat-radio-label-content
    padding 0
    font-weight var(--fw-regular)
    font-size var(--fs-h4)
    line-height var(--lh-h4)
    font-family var(--font-family)

  .mat-radio-outer-circle
    border-color var(--gray2-color)
    transform scale(0.9)
    border-width 1px

  &.mat-radio-checked
    .mat-radio-outer-circle
      border-color var(--brand-color)
    .mat-radio-inner-circle
      transform scale(0.54)

  &.mat-radio-checked .mat-radio-persistent-ripple,
  &:active .mat-radio-persistent-ripple
  .mat-radio-inner-circle,
  .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple)
    background-color var(--brand-color)



