.mat-slide-toggle
  .mat-slide-toggle-bar
    margin-right 12px
    background-color rgba(0, 0, 0, .38)

    .mat-slide-toggle-thumb
      box-shadow 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12)
      background-color var(--gray2-color)

    .mat-ripple-element
      background-color var(--black-color)

.mat-slide-toggle.mat-checked
  .mat-slide-toggle-bar
    background-color var(--green-secondary-color)

    .mat-slide-toggle-thumb
      background-color var(--brand-color)

    .mat-ripple-element
      background-color var(--brand-color)

.kkm-slide-toggle.mat-slide-toggle
  .mat-slide-toggle-bar
    width 44px
    height 24px
    border-radius 12px
    background-color var(--gray4-color)
    .mat-slide-toggle-thumb-container
      width 21px
      height 21px
      top 2px
      transform translate3d(2px, 0, 0)
      .mat-slide-toggle-thumb
        box-shadow none
        background-color var(--white-color)
      .mat-slide-toggle-ripple
        display none
  .mat-slide-toggle-content
    display flex
    @media screen and (max-width: 768px)
      white-space break-spaces
  &.mat-checked
    .mat-slide-toggle-bar
      background-color var(--brand-color)
      .mat-slide-toggle-thumb-container
        transform translate3d(22px, 0, 0)

