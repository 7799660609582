/* KKM Form Field Component */
mat-form-field.kkm-form-field.mat-form-field
  @extend .body_text
  line-height 2rem
  box-sizing border-box
  &.gray
    .mat-form-field-wrapper
      background-color var(--gray-color)
  .mat-form-field-wrapper
    background-color: var(--white-color)

  .kkm-icon-button.mat-icon-button
    width 1.6rem !important
    height 1.6rem !important


  .mat-form-field-label
    font-weight var(--fw-regular)
    font-size var(--fs-h4)
    line-height  var(--lh-h4)
    font-family var(--font-family)
    color var(--fg-secondary-color)

  .mat-form-field-prefix, .mat-form-field-suffix
    svg, path, rect
      fill var(--fg-secondary-color)
      opacity 0.7

  .mat-form-field-required-marker
    display none

  /* Appearance = fill */
  &.mat-form-field-appearance-fill
    .mat-form-field-flex
      &:hover
        border 0.1rem solid var(--gray1-color)

    &.mat-focused
      .mat-form-field-flex
        border 0.1rem solid var(--brand-focus-color)
        padding 0.5rem 1.5rem 0 1.5rem

    .mat-form-field-flex
      transition all 0.15s ease-in-out
      border 0.1rem solid var(--border-color)
      border-radius 0.2rem
      padding 0.5rem 1.6rem 0 1.6rem
      max-height 52px
    .mat-form-field-infix
      padding-bottom 0.95rem
      width 100%
      .mat-select
        .mat-select-arrow
          margin-top 0.5rem
    .mat-form-field-suffix
      .mat-icon-button
        margin-left 5px
        height auto
        width auto

    .mat-form-field-wrapper
      padding-bottom 0

    &.mat-form-field-disabled
      .mat-form-field-flex
        background-color var(--bg-primary-color)
        border 1px solid var(--fg-disabled-color)

        &:hover
          border 1px solid var(--fg-disabled-color)

    &.mat-form-field-invalid.ng-dirty.ng-touched:not(.range-invalid)
      .mat-form-field-flex
        padding 0.5rem 1.5rem 0 1.5rem
        border 2px solid var(--red1-color)
      .mat-form-field-subscript-wrapper
        margin-top 2.4rem
        .mat-error
          font 400 13px/18px var(--font-family)
          color var(--red1-color)
    &.mat-form-field-invalid.ng-dirty.ng-touched.range-invalid
      .mat-form-field-subscript-wrapper
        margin-top 2.4rem
        .mat-error
          font 400 13px/18px var(--font-family)
          color var(--red1-color)
  &.compact
    &.mat-form-field-appearance-standard
      .mat-form-field-wrapper
        background-color: transparent
        padding-bottom 0
        .mat-form-field-flex
            padding-top 0
            .mat-form-field-infix
              color: var(--brand-color)
              font-size 2rem
              line-height 2.4rem
              font-weight: 600
              border-top: 0
              padding 0
              width auto !important
              .mat-select-value
                max-width: 100%
                padding-right 0.4rem
                font-family var(--font-family)
    &.mat-form-field-appearance-fill
      .mat-select-arrow-wrapper
         transform: translateY(0%);
         .mat-select-arrow
           margin-top 0

      .mat-form-field-flex
        align-items center
        padding: 7px 1.6rem !important
        height: 4rem
        &:hover
          border 1px solid var(--gray1-color)
        .mat-form-field-prefix
          padding-right: 0.8rem
          .mat-icon
            font-size: 100%
            line-height: 1
            width: 2.4rem !important
            height: 2.4rem !important
        .mat-form-field-suffix
          .mat-icon-button
            margin-left 5px
            height auto
            width auto
          .mat-icon
            font-size: 100%
            line-height: 1
            width: 1.6rem
            height: 1.6rem
        .mat-form-field-infix
          border-top: none
          padding: 0
          font-size 1.5rem
          line-height 2.4rem
          .mat-form-field-label-wrapper ng-tns-c70-0
            display: none
      &.mat-focused
        .mat-form-field-flex
          border 0.1rem solid var(--brand-focus-color)
          padding 0.6rem 1.5rem
    &.medium
      .mat-form-field-flex
        padding: 1rem 1.6rem
        height: 4.8rem
  &.no-label
    .mat-form-field-flex
      padding-top 0 !important
      padding-bottom 0 !important
      height 5.6rem !important
      display flex
      align-items center
      .mat-form-field-infix
        border-top 0
        padding-top 0
        padding-bottom 0
    &.medium
      .mat-form-field-flex
        height: 4.8rem !important
        .mat-form-field-prefix
          .mat-icon
            line-height 1 !important
            margin-right 1.6rem

  &.small-x-padding
    .mat-form-field-flex
      padding-left 5px !important
      padding-right 5px !important
.mat-select-panel.kkm-select-panel, .mat-select-panel.kkm-select-panel__compact
    background-color var(--white-color)
    box-shadow 0px 0px 2px rgba(0, 0, 0, 0.15)
    border-radius 0.2rem
    .mat-option
      &.mat-selected
        background-color var(--brand-light-color)
      transition background-color 0.2s ease

.mat-form-field-underline
      &:before
        height: 0 !important

.mat-pseudo-checkbox
  margin-bottom 0.2rem
  border-color var(--gray2-color) !important
  &.mat-pseudo-checkbox-checked
    background-color var(--brand-color)
    border-color var(--brand-color) !important
    color var(--white-color)

.cdk-overlay-pane
  &:not(.mat-datepicker-popup):not(.kkm-dialog_panel):not(.kkm-datepicker__overlay_start_bottom_start_top):not(.kkm-overlay-panel__overlay_start_bottom_start_top)
    transform: translate(-1.7rem, -1rem) !important;
  .mat-autocomplete-panel
    background-color var(--white-color)
    transform: translate(1.7rem, 1rem) !important;
    box-shadow 0px 0px 2px rgba(0,0,0,0.15)
    .mat-option
      &.mat-active,
      &.mat-selected,
      &:hover
        background-color var(--brand-light-color)
    &.select-autocomplete
      box-shadow 0px 0px 11px rgba(0, 0, 0, 0.15)
  .kkm-select-panel
    min-width: calc(100% + 3.4rem) !important
    border: 1px solid var(--gray2-color);
    transform: translate(0, 4rem) !important;
    &__compact
      min-width: calc(100% + 3.4rem) !important
      border: 1px solid var(--gray2-color);
      transform: translate(0, 4.1rem) !important;
.cdk-overlay-pane[style*='bottom'] .kkm-select-panel
    transform: translate(0, -3.5rem) !important
    &__compact
      transform: translate(0, -2.2rem) !important

.kkm-select-arrow
  width 0
  height 0
  border-left 6px solid transparent
  border-right 6px solid transparent
  border-top 7px solid
  margin 0 4px

mat-form-field.kkm-form-field.mat-form-field.webcr
  &.mat-form-field-appearance-fill
    .mat-form-field-flex
      border-radius 0.4rem

mat-form-field.kkm-field-error:not(.kkm-mat-datepicker)
  .mat-form-field-wrapper
    .mat-form-field-subscript-wrapper
      position relative
      min-height 2.2rem
      margin-top 0.4rem !important
      margin-bottom -2rem
      padding 0

mat-form-field.kkm-field-error.condition-field-error
  .mat-form-field-wrapper
    .mat-form-field-subscript-wrapper
      background var(--gray-color)
      margin-top 0 !important
      padding-top 0.4rem !important

.kkm-form-field-datepicker
  width 18rem
  max-width 18rem

mat-form-field.kkm-form-field.mat-form-field.compact.compact__page-number
  .mat-form-field-flex
    height 24px !important
    padding 0.7rem 0.5rem !important
  input
    font-size var(--fs-gh)
    text-align center
