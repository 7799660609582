.sat-popover-container
  .kkm-popover
    background var(--white-color)
    color var(--black-color)
    font-size: 13px;
    line-height: 20px;
    box-shadow 0px 0px 4px rgba(0, 0, 0, 0.15);
    border-radius 2px
    padding: 18px 9.5px
    margin-left 15px
    position relative
    &:after, &:before
      right: 100%;
      top: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    &:before
      border-color: rgba(194, 225, 245, 0);
      border-color: rgba(136, 183, 213, 0);
      border-right-color: var(--white-color);
      border-width: 10px;
      margin-top: -10px;
      filter drop-shadow(rgba(0, 0, 0, 0.05) -2px 0px 1px)