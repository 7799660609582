mat-form-field.kkm-form-field.mat-form-field
  .mat-form-field-flex .mat-form-field-suffix .mat-datepicker-toggle
    .mat-icon-button
      background-color transparent
      .mat-icon
        height 2rem !important
        width 2rem !important

  &:not(.compact)
    .mat-form-field-flex .mat-form-field-suffix .mat-datepicker-toggle
      .mat-icon-button
        transform translateY(-3px)

.mat-datepicker-popup
  border 1px solid #e5e5e5
  border-radius 0.4rem;
  .mat-datepicker-content
    .mat-calendar
      font-family: var(--font-family)
      background-color var(--white-color)
      .mat-calendar-header
        .mat-calendar-controls
          .mat-calendar-period-button
            background-color transparent
          .mat-calendar-previous-button, .mat-calendar-next-button
            background-color transparent
      .mat-calendar-content
        .mat-calendar-table
          .mat-calendar-table-header
          .mat-calendar-body
            .mat-calendar-body-label:not(:last-of-type)
              visibility hidden
            .mat-calendar-body-label:last-of-type
              display none
            .mat-calendar-body-cell
              &.mat-calendar-body-disabled
                color var(--gray1-color)
              &.mat-calendar-cell-semi-selected,
              &.mat-calendar-body-in-range,
              &.mat-calendar-body-in-preview
                background-color var(--brand-light-color)
                border-radius 0
                .mat-calendar-body-cell-preview
                  border none !important
              &.mat-calendar-body-begin-range:not(.mat-calendar-body-end-range),
              &.mat-calendar-body-range-start:not(.mat-calendar-body-range-end),
              &.mat-calendar-body-preview-start:not(.mat-calendar-body-preview-end)
                border-radius 100% 0 0 100%
                background-color var(--brand-light-color)
              &.mat-calendar-body-end-range:not(.mat-calendar-body-begin-range),
              &.mat-calendar-body-range-end:not(.mat-calendar-body-range-start),
              &.mat-calendar-body-preview-end:not(.mat-calendar-body-preview-start)
                border-radius 0 100% 100% 0
                background-color var(--brand-light-color)
              .mat-calendar-body-cell-content
                border-color transparent
                &.mat-calendar-body-today
                  border 1px solid var(--gray1-color)
                &.mat-calendar-body-selected
                  background-color var(--brand-color)
                  border-color var(--brand-color)
                  color var(--white-color)
                &.mat-calendar-body-semi-selected
                  background-color var(--brand-light-color)
                  color var(--black-color)

.kkm-datepicker
  .mat-form-field-flex
    padding-right 0 !important
    .mat-form-field-suffix
      padding-right 0 !important

.kkm-datepicker-toggle
  .mat-icon-button
    background-color transparent

.kkm-mat-datepicker.mat-form-field-type-mat-date-range-input
  .mat-form-field-wrapper
    padding-bottom 0
    background-color var(--white-color)
    .mat-form-field-flex
      border 1px solid var(--border-color)
      border-radius 0.2rem
      height 4rem
      padding 0.7rem 1.6rem
      align-items center
      width 100%
      margin 0
      .mat-form-field-infix
        width auto
        border-top 0
        padding-bottom 0
        padding-top 0
        font-size 1.6rem
        line-height 2.4rem
        font-weight normal
        color var(--black-color)
      .mat-form-field-suffix
        display flex
        align-items center
        .mat-datepicker-toggle
          color red
          background-color transparent
        .mat-icon.mat-button
          padding 0 !important
          min-width auto
          margin-left 0.8rem
  &.simple-datepicker
    min-width auto
    .mat-form-field-flex
      border none
      padding-left 1rem
      padding-right 0
      .mat-form-field-infix
        .mat-date-range-input
          display none

.kkm-month-view,
.kkm-year-view
  width 100%
  display block

  table.mat-calendar-table
    border-spacing 0
    width 100%

.kkm-year-view
  margin-top 27px

  .mat-calendar-table-header
    display none

  .mat-calendar-body
    tr:first-child
      display none

    &-label
      display none

    &-cell
      width 84px !important
      height 48px
      padding 0 !important

    &-cell-content
      width 84px
      height 48px
      border-radius 50px
      top 0
      left 0
      font-size var(--fs-h4)
      line-height var(--lh-gh)
      border none

      &:hover
        background-color var(--brand-light-color)

      &.mat-calendar-body-selected
        background var(--brand-color)
        color var(--white-color)

.kkm-month-view
  margin-top 19px
  .mat-calendar-body
    &-label
      visibility hidden
    &-cell
      width 48px !important
      height 48px !important
      padding 0 !important
      border-radius 50%
    &-cell:not(.mat-calendar-body-disabled):hover
      background-color: var(--brand-light-color);
    &-cell-content
      border none
      font-size var(--fs-h4)
      line-height var(--lh-gh)
      position relative !important
      top 0
      left 0
      width 48px
      height 48px
    &-today
      border 1px solid var(--gray1-color)
    &-selected
      background var(--brand-color)
      color var(--white-color)
      border none
    &-in-range, &-in-preview, &-preview-end
      background-color var(--brand-light-color)
      border-radius 0
    &-in-preview
      .mat-calendar-body-cell-preview
        border none
    &-preview-end
      border-radius 0 50% 50% 0
    &-range-start
      border-radius 50% 0 0 50%
    &-range-end
      border-radius 0 50% 50% 0
  .mat-calendar-table-header
    th
      font-size var(--fs-gh)
      line-height var(--lh-gh)
      color: var(--gray1-color)

.kkm-mat-calendar-header
  .mat-calendar-controls
    display flex
    justify-content space-between
    align-items center
    margin-top 27px

  .mat-calendar-period-button
    background transparent
