.mat-icon
  width 2rem !important
  height 2rem !important

  svg
    display block

  &.small
    width 1.2rem !important
    height 1.2rem !important
  &.medium
    width 1.6rem !important
    height 1.6rem !important
  &.large
    width 2.2rem !important
    height 2.2rem !important
  &.very-large
    width 2.4rem !important
    height 2.4rem !important

  &.mat-button
    cursor: pointer
    font-size 0
  &.back-button
    width 1.2rem !important
    height 1rem !important

  &.white
    svg, path, rect
      fill var(--white-color) !important
  &.gray
    svg, path, rect
      fill var(--gray1-color) !important
  &.dark
    svg, path, rect
      fill var(--fg-primary-color) !important
  &.brand
    svg, path, rect
      fill var(--brand-color) !important
  &.red
    svg, path, rect
      fill var(--red1-color) !important
  &.search-applied
    svg, path, rect
      fill var(--brand-color) !important
      opacity .85 !important

