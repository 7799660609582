mat-tooltip-component
  opacity 0.8 !important

  .mat-tooltip
    overflow visible
    background #121010
    border-radius 2px
    padding 8px 16px
    font-size 13px
    line-height 18px
    letter-spacing 0.2px

  .kkm-table-tooltip-above
    position relative !important
    margin-bottom -15px !important

  .kkm-table-tooltip-above:after
    content: "";
    position: absolute;
    border-color: #121010 transparent transparent transparent;
    border-style: solid;
    border-width: 5px;
    top: 100%;
    left: 50%;

  .kkm-icon-tooltip-below
    position relative !important
    margin-right -20px !important

  .kkm-icon-tooltip-below:after
    content: "";
    position: absolute;
    border-color: transparent transparent #121010 transparent;
    border-style: solid;
    border-width: 5px;
    bottom: 100%;
    left: 45%;
