@import './mixins.styl'

html, body
  height 100%
  font-size 10px
html
  box-sizing border-box
  -ms-overflow-style scrollbar

html body div
  & span, div, h1, h2, h2, h3, h4, h5, input, button, mat-option
    font-family var(--font-family) !important

body
  margin 0
  padding 0
  letter-spacing 0.3px
*, *::before, *::after
  padding 0
  margin 0
  box-sizing inherit

[app-root]
  height 100%
  min-width 1128px

h1
  font-weight var(--fw-semi-bold)
  font-size var(--fs-h1)
  line-height  var(--lh-h1)
  font-family var(--font-family)
  color var(--fg-primary-color)
h2
  font-weight var(--fw-semi-bold)
  font-size var(--fs-h2)
  line-height  var(--lh-h2)
  font-family var(--font-family)
  color var(--fg-primary-color)
h3
  font-weight var(--fw-semi-bold)
  font-size var(--fs-h3)
  line-height  var(--lh-h3)
  font-family var(--font-family)
  color var(--fg-primary-color)
h4
  font-weight var(--fw-semi-bold)
  font-size var(--fs-h4)
  line-height  var(--lh-h4)
  font-family var(--font-family)
  color var(--fg-primary-color)
.decor-title
  font-weight var(--fw-semi-bold)
  font-size var(--fs-decor-title)
  line-height  var(--lh-decor-title)
  font-family var(--font-family)
  color var(--fg-primary-color)
.display-title
  font-weight var(--fw-semi-bold)
  font-size var(--fs-display)
  line-height  var(--lh-display)
  font-family var(--font-family)
  color var(--fg-primary-color)
.body_text
  font-weight var(--fw-regular)
  font-size var(--fs-h3)
  line-height  var(--lh-h3)
  font-family var(--font-family)
  color var(--fg-primary-color)
.body_text_extended
  font-weight var(--fw-regular)
  font-size var(--fs-h4)
  line-height  var(--lh-h4)
  font-family var(--font-family)
  color var(--fg-primary-color)
.hint_text
  font-weight var(--fw-regular)
  font-size var(--fs-h4)
  line-height  var(--lh-h4)
  font-family var(--font-family)
  color var(--fg-secondary-color)
  &.small
    font-weight var(--fw-regular)
    font-size var(--fs-xs)
    line-height  var(--lh-xs)
    font-family var(--font-family)
.white_text
  color var(--white-color)
.bg_text
  color var(--brand-bg-text-color)
.small_text
  font-weight var(--fw-regular)
  font-size var(--fs-gh)
  line-height  var(--lh-gh)
  font-family var(--font-family)
.large_text
  font-weight var(--fw-regular)
  font-size var(--fs-h3)
  line-height  var(--lh-h3)
  font-family var(--font-family)
.muted_text
  color var(--fg-secondary-color)
.lowercase
  text-transform lowercase
.grid_header
  font-weight var(--fw-semi-bold)
  font-size var(--fs-gh)
  line-height  var(--lh-gh)
  font-family var(--font-family)
  color var(--fg-primary-color)
.semi_bold
  font-weight 500 !important
.bold
  font-weight 600 !important
.fw-regular
  font-weight var(--fw-regular)
.pre-wrap
  white-space pre-wrap
.brand-color
  color var(--brand-color-dark) !important
.red-color
  color var(--red1-color) !important
.gray1-color
  color var(--gray1-color) !important

a
  font-weight var(--fw-medium)
  font-size var(--fs-h4)
  line-height  var(--lh-h4)
  font-family var(--font-family)
  color var(--fg-link-color)
  cursor pointer

margin-step = 0.4rem
for i in (0..20)
  .m_t-{i}, .m_y-{i}
    margin-top margin-step * i
  .m_t-n{i}, .m_y-n{i}
    margin-top margin-step * i * (-1)
  .m_b-{i}, .m_y-{i}
    margin-bottom margin-step * i !important
  .m_b-n{i}, .m_y-n{i}
    margin-bottom margin-step * i * (-1)
  .m_l-{i}, .m_x-{i}
    margin-left margin-step * i !important
  .m_l-n{i}, .m_x-n{i}
    margin-left margin-step * i * (-1)
  .m_r-{i}, .m_x-{i}
    margin-right margin-step * i
  .m_r-n{i}, .m_x-n{i}
    margin-right margin-step * i * (-1)
  .p_t-{i}, .p_y-{i}
    padding-top margin-step * i
  .p_b-{i}, .p_y-{i}
    padding-bottom margin-step * i
  .p_l-{i}, .p_x-{i}
    padding-left margin-step * i
  .p_r-{i}, .p_x-{i}
    padding-right margin-step * i

.m_b-half
  margin-bottom margin-step * 0.5

.align-left
  text-align left
.align-right
  text-align right
.align-center
  text-align center


.flex
  display flex


.h-fluid
  height 100%
.h-0
  height 0
.w-fluid
  width 100%
.w-0
  width 0
.w-auto
  width auto !important
.min-w-auto
  min-width auto !important
.of-auto
  overflow auto
.of-hidden
  overflow hidden
.of-scroll
  overflow scroll
.opacity-zero
  opacity 0
.sticky-top
  position sticky
  top 0
.sticky-bottom
  position sticky
  bottom  0


.pointer
  cursor pointer

.no-text-decoration
  text-decoration none

.underline
  text-decoration underline

input:-internal-autofill-selected
  background-color transparent !important;
  color inherit !important;


.box_shadow
  box-shadow 0 0 2px rgba(0, 0, 0, 0.15)
.shadow-none
  box-shadow none !important

.gray2_border
  border 1px solid var(--gray2-color) !important

.bottom_border
  border-bottom: 1px solid var(--gray2-color)

.transparent-area
  background-color transparent !important;
.white-area
  background-color var(--white-color)
.primary-area
  background-color var(--bg-primary-color)
.secondary-area
  background-color var(--bg-secondary-color)
.warning-area
  background-color var(--bg-warning-color)
.success-area
  background-color var(--bg-success-color)
  padding 1.6rem
  &_absolute-top
    position absolute
    transform translateX(-50%)
    left 50%
    top 0.8rem
    z-index 3000
.fail-area
  font-weight var(--fw-regular)
  font-size var(--fs-h4)
  line-height  var(--lh-h4)
  font-family var(--font-family)
  color var(--fg-primary-color)
  background-color var(--bg-fail-color)
  padding 1.6rem
  border-radius 0.2rem
  &_absolute-top
    position absolute
    transform translateX(-50%)
    left 50%
    top 0.8rem
    z-index 3000
    .reload-page-link
      color #000000
      font-weight var(--fw-semi-bold)
      text-decoration underline
  &.webcr
    border-radius 0.4rem
.filter-area
  padding-top 1.2rem
  padding-bottom 1.2rem
  box-shadow 0 0 2px rgba(0, 0, 0, 0.15)
  margin 1px auto

.sidepage
  padding 2.4rem 2.4rem 3.2rem 4.8rem
  &__overlay
    position absolute
    display none
    z-index 1
    top 0
    right 0
    bottom 0
    left 0
    &.is-opened
      display block

.in_progress
  display flex
  flex-direction row
  justify-content flex-start
  align-items center
  color var(--fg-secondary-color)
  &:before
    content attr(data-text)
    margin-right 2.4rem
  &:after
    content ""
    display block
    width 2.4rem
    height 2.4rem
    background url("/assets/spinner-small.svg") center no-repeat
    background-size 2.4rem
    animation-name: rotation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

.preloader
  display flex
  flex-direction row
  justify-content center
  align-items center
  &:before
    content ""
    display block
    background url("/assets/spinner-small.svg") center no-repeat
    animation-name: rotation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  &.small:before
    width 2.4rem
    height 2.4rem
    background-size 2.4rem
  &.middle:before
    width 5rem
    height 5rem
    background-size 5rem
  &.big:before
    width 10rem
    height 10rem
    background-size 10rem

.position-relative
  position relative

.position-absolute
  position absolute

.visible
  visibility visible !important

.invisible
  visibility hidden !important

@keyframes rotation
  0%
    transform rotate(0deg)
  100%
    transform rotate(360deg)

@import "./material/index.styl"

*
  scrollbar-width thin
  scrollbar-color var(--gray2-color) var(--white-color)

::-webkit-scrollbar
  width 0.8rem

/* Track */
::-webkit-scrollbar-track
  background var(--white-color)

/* Handle */
::-webkit-scrollbar-thumb
  background var(--gray2-color)
  &:hover
    background var(--gray2-color)

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.link
  color var(--brand-dark-color)
  cursor pointer
  text-decoration none

.no-data-area
  padding: 60px 0;

  &__image
    width: 362px;
    height: 280px;


.fn-status
  border-radius 20px
  display flex
  flex-direction row
  align-items center
  padding 4px 12px
  font-weight 600
  font-size 13px
  line-height 24px
  text-transform uppercase
  &__free
    background-color #CAE6FF
    color #707070
  &__awaiting
    background-color #FFEDC2
    color #707070
  &__in-use
    background-color #CCF0E6
    color #00A38D
  &__expired
    background-color rgba(244, 91, 105, 0.3)
    color #707070
  &__lost, &__disabled
    background-color #DFE0EB
    color #707070

.yt-cluster
  display flex
  flex-direction column
  align-items center
  cursor pointer
  font-size 13px
  font-weight 600

  &__circle
    width 60px
    height 60px
    border-radius 30px
    background-color rgba(24, 97, 219, 0.3)
    display flex
    align-items center
    justify-content center
    color #ffffff
    div
      width 36px
      height 36px
      border-radius 18px
      background-color #1861DB
      display flex
      align-items center
      justify-content center

    &.animate
      animation: outer 800ms ease-in-out

      div
        animation: inner 800ms ease-in-out

      @keyframes outer
        from
          background-color rgba(24, 97, 219, 0.3)
          width 60px
          height 60px
        25%
          background-color rgba(242, 187, 39, 0.3)
          width 64px
          height 64px
        75%
          background-color rgba(242, 187, 39, 0.3)
          width 64px
          height 64px
        to
          background-color rgba(24, 97, 219, 0.3)
          width 60px
          height 60px

      @keyframes inner
        from
          background-color #1861DB
        25%
          background-color #F2BB27
        75%
          background-color #F2BB27
        to
          background-color #1861DB

    &__container
      display flex
      align-items center
      justify-content center
      width 64px
      height 64px

  &__tooltip
    z-index 1
    background-color #ffffff
    position relative
    font-weight 600
    height 36px
    min-width 87px
    border-radius 8px
    display flex
    align-items center
    justify-content center
    box-shadow 0px 0px 5px rgba(0, 0, 0, 0.15)
    padding 0 6px

    &:after
      content ""
      border-color #ffffff transparent transparent transparent
      position absolute
      border-style solid
      border-width 8px
      top 100%
      left 42%

.ya-marker
  text-align center
  cursor pointer
  font-size 13px
  font-weight 600

  &__tooltip
    position relative
    font-weight 600
    height 36px
    min-width 87px
    border-radius 8px
    display flex
    align-items center
    justify-content center
    box-shadow 0px 0px 5px rgba(0, 0, 0, 0.15)
    padding 0 6px

    &.inactive
      background-color #ffffff
      &:after
        content ""
        border-color #ffffff transparent transparent transparent
        position absolute
        border-style solid
        border-width 8px
        top 100%
        left 42%

    &.active
      background-color #1861DB
      color #ffffff
      &:after
        content ""
        border-color #1861DB transparent transparent transparent
        position absolute
        border-style solid
        border-width 8px
        top 100%
        left 42%

.taxi-top-first
  background-color #FF495A
.taxi-top-second
  background-color #6AB46D
.taxi-top-third
  background-color #1A85DD
.taxi-top-fourth
  background-color #FA7921
.taxi-top-fifth
  background-color #9F6EC4
.taxi-top-others
  background-color #FFD166

.border-box {
  box-sizing border-box
}
