/* KKM Stepper */
.kkm-ui-vertical-stepper
  position: relative;

  .mat-step
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    position: static;
    height: 96px;

    .mat-step-header
      flex: 0 0 25%;
      max-width: 25%;
      padding: 0;

    .mat-vertical-content-container
      flex: 0 0 75%;
      width: 75%;
      max-width: 75%;
      padding: 0;

  .mat-vertical-stepper-header
    height: 100%;
    display: inline-flex;
    align-items: flex-start;
    pointer-events: none !important;

  .mat-step-icon
    position relative
    height 44px
    width 44px
    font-size var(--fs-h2)
    line-height var(--lh-h2)
    font-weight var(--fw-semi-bold)
    color var(--gray1-color)
    border 2px solid var(--gray1-color)
    opacity 0.6

  .mat-step-icon::before
    content: "";
    border-left: 2px solid var(--gray1-color);
    opacity: 0.6;
    top: 54px;
    bottom: -42px;
    position: absolute;
    left: calc(50% - 1px);

  .mat-step:last-child
    .mat-step-icon:before
      content: none;

  .mat-step-icon-selected
    color: #fff;
    background-color: var(--blue-color);
    opacity: 1;
    border-color: var(--blue-color);

  .mat-vertical-content-container
    margin: 0;
    border: 0;
    position: absolute;
    top: 0;
    right: 0;

  .mat-stepper-vertical-line::before
    content: none;

  &__step-no
    display: block;
    text-transform: uppercase;
    color: var(--gray1-color);
    font-size: 11px;
    line-height: 20px;
    margin-bottom: 2px;
    opacity: 0.6;

  &__step-title
    display block
    color var(--black-color)
    font-size var(--fs-h3)
    line-height var(--lh-h3)
    opacity 0.6
    padding-right 36px

  .mat-step-label
    white-space: break-spaces;

  .mat-step-label-active
    .kkm-ui-vertical-stepper__step-no, .kkm-ui-vertical-stepper__step-title
      font-weight: normal;
      opacity: 1;

  .mat-step-icon-state-edit
    border-color: var(--green1-color);
    background: var(--green1-color) no-repeat 12px center url("../../icons/common/checkmark.svg");

    &:before
      border-left-color: var(--green1-color);

    .mat-icon
      display: none;

  .mat-vertical-content
    padding 0;
    margin: 0;

  &__content-card
    background: #fff;
    padding: 3.2rem;
    margin-bottom: 3.2rem;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.04);
    border-radius: 2px;
    min-height: 450px;
