/* KKM Button */
button.kkm-button
  transition all 0.15s ease-in-out
  &.mat-button, &.mat-stroked-button
    font-weight var(--fw-medium)
    font-size var(--fs-h4)
    line-height  var(--lh-h4)
    font-family var(--font-family)
    color var(--fg-primary-color)
    border-radius 0.2rem
  // &.outlined
  //   color var(--brand-color)
  //   border 1px solid var(--brand-color)
  //   background-color var(--white-color)
  //   font-size 1.6rem
  //   line-height 2.4rem
  //   padding 1.4rem 1.6rem
  //   cursor pointer
  //   height 5.6rem
  //   &[disabled="true"]
  //     opacity 0.5

  // &.outlined
  //   border 1px solid #EDEEF9
  //   background-color var(--white-color)
  //   color var(--brand-color)
  //   &:hover:not([disabled="true"]), &:active, &:focus
  //     border-color var(--brand-color)
  //     background-color #EDFAF6

  //   &[disabled="true"]
  //     opacity 0.5

  &_compact
    height 4.8rem !important
    padding 0.8rem 3.2rem !important

  &_loading
    &:before
      content ""
      position absolute
      background-image url("/assets/loader-mini.svg")
      background-repeat no-repeat
      background-position center
      width 28px
      height 28px
      top 50%
      left 50%
      transform translateX(-50%) translateY(-50%) rotate(0deg)
      animation-name: rotate-loader;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    &-brand-color
      &:before
        background-image url("/assets/loader-mini-brand-color.svg")

  &.outlined
    border 1px solid #EDEEF9
    background-color var(--white-color)
    color var(--brand-color) !important
    &:hover:not([disabled="true"])
      border-color var(--brand-color)
    &:active:not([disabled="true"])
      border-color var(--brand-color)
      color var(--brand-focus-color) !important
    &:focus:not([disabled="true"])
      border-color var(--brand-focus-color)
      color var(--brand-focus-color) !important

    &[disabled="true"]
      opacity 0.5


  &.mat-button
    padding 1.4rem 3.2rem
    height 5.2rem

    &.narrow
      padding 0.8rem 1.6rem
      font-weight var(--fw-medium)
    &.action
      min-width: 160px
      height: 56px
    &.wide
      min-width 10.9rem
    &.fixed-height
      height 4rem
      padding-bottom 0
      padding-top 0
    &.link
       border none
       text-decoration underline
       padding 0.8rem 1.6rem
       &.primary
        &[disabled="true"]
          opacity 0.5

        &:not([disabled="true"])
          &:hover
            color var(--brand-hover-color)

          &:active, &:focus
            color var(--brand-focus-color)

    &.mat-button-base
      &.mat-primary
        background-color var(--brand-color)
        color var(--primary-button-color)
        &[disabled="true"]
          opacity 0.5

        &:not([disabled="true"])
          &:hover
            background-color var(--brand-hover-color)

          &:active, &:focus
            background-color var(--brand-focus-color)

    .mat-button-wrapper
      .mat-icon + span
        margin-left 8px

  &.mat-stroked-button
    padding 1.5rem 1.6rem
    border 1px solid var(--border-color)
    color var(--black-color)
    &.primary-color
      color var(--brand-color) !important
    &.narrow
      padding 0.7rem 1.6rem
      min-width 12rem
    &.auto-min-width
      min-width auto
    &.wide
      min-width 10.9rem
    &.action
      min-width 160px
      height 56px
    &.link
      border none
      text-decoration underline
      padding 0.8rem 1.6rem

    &.mat-button-base
      &.mat-primary
        background-color var(--white-color)

        &[disabled="true"]
          opacity 0.5

        &:not([disabled="true"])
          &:hover
            border-color var(--brand-hover-color)

          &:active, &:focus
            border-color var(--brand-focus-color)

  &.mat-icon-button,
  &.kkm-icon-button
    background-color transparent
    border none
    outline none
      .mat-icon
        &.medium
          height: 1.6rem !important
          width: 1.6rem !important
        &.large
          height: 2rem !important;
          width: 2rem !important;
        &.small
          height: 1.2rem !important;
          width: 1.2rem !important;
    &.small
      .mat-icon
        height: 1.6rem
        width: 1.6rem
    &.medium
      height 2.4rem
      width 2.4rem
      line-height 2.4rem

button.icon-button
  background-color transparent
  border none
  outline none
  cursor pointer
  .mat-icon
    &.small
      height: 1.2rem !important;
      width: 1.2rem !important;
    &.medium
      height: 1.6rem !important
      width: 1.6rem !important
    &.large
      height: 2rem !important;
      width: 2rem !important;
    &.giant
      height: 2.4rem !important;
      width: 2.4rem !important;

button.webcr.kkm-button
  &.mat-button, &.mat-stroked-button
    border-radius 0.4rem !important

  &.outlined
    border 1px solid var(--brand-color)
    background-color var(--white-color)
    color var(--brand-color)
    &:hover:not([disabled="true"]), &:active, &:focus
      border-color var(--brand-color)
      background-color var(--color-primary-light)

    &[disabled="true"]
      opacity 0.5

  @media (max-width: 767px)
    height 4.8rem !important
    padding 1.2rem 3.2rem !important

.eye-button
  visibility hidden

  &_visible-by-input
    .eye-button
      visibility visible

  &_visible-by-hover:hover
    visibility visible

button.reset-filter-button
  display flex
  justify-content center
  align-items center
  color var(--brand-color)
  background-color var(--white-color) !important
  border-radius 0.2rem
  border 1px solid var(--gray2-color) !important
  .mat-icon
    width 12px !important
    height 12px !important

.kkm-button__filters
  &_show
    background-color #F7F8FC !important
  &-count
    width 1.6rem
    height 1.6rem
    border-radius 50%
    display inline-flex
    align-items center
    justify-content center
    color #FFF
    background var(--brand-color)
    font-style normal
    font-size 10px
    line-height 12px
    letter-spacing 0.2px
    margin-left 8px

@keyframes rotate-loader
  0%
    transform translateX(-50%) translateY(-50%) rotate(0deg)
  100%
    transform translateX(-50%) translateY(-50%) rotate(360deg)
