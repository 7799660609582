.mat-checkbox
  .mat-checkbox-inner-container
    width 1.8rem
    height 1.8rem
    background-color var(--white-color)
    margin-right 1.2rem
  .mat-checkbox-label
    display flex
  &.mat-checkbox-checked
    .mat-checkbox-frame
      border-color transparent
      background-color: var(--brand-color)

  .mat-checkbox-frame
    border-color var(--gray2-color)
    background-color: transparent


.kkm-mat-autocomplete__checkbox
  .mat-checkbox-layout
    width 100%
    height 48px
    align-items center
  .mat-checkbox-inner-container
    margin-left 0
