// Import Bootstrap Grid's styles
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
/* Customization */
$grid-columns:                12;
$grid-gutter-width:           24px;
$grid-row-columns:            12;
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1128px,
  xl: 1281px
);
$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: 100%
);
/* /Customization */
@import "~bootstrap/scss/mixins/breakpoints";
@import "~bootstrap/scss/mixins/grid-framework";
@import "~bootstrap/scss/mixins/grid";
@import "~bootstrap/scss/mixins/hover";
@import "~bootstrap/scss/mixins/deprecate";
@import "~bootstrap/scss/mixins/text-hide";

@import "~bootstrap/scss/mixins/text-truncate";
@import "~bootstrap/scss/mixins/text-emphasis";

@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/utilities/display";
@import "~bootstrap/scss/utilities/flex";
@import "~bootstrap/scss/utilities/spacing";
@import "~bootstrap/scss/utilities/text";

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
/**/
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
/**/
$kkm-ui-cloud-fn-primary: mat-palette($mat-amber); // $mat-indigo
$kkm-ui-cloud-fn-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
/**/
$kkm-ui-cloud-fn-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
/**/
$kkm-ui-cloud-fn-theme: mat-light-theme($kkm-ui-cloud-fn-primary, $kkm-ui-cloud-fn-accent, $kkm-ui-cloud-fn-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
/**/
 //@include angular-material-theme($kkm-ui-cloud-fn-theme);
