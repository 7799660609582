@import "../../../libs/shared-assets/styles/colors.styl";

:root
  /* Colors definition */
  --blue-color: #9FB8EB
  --blue-l1-color: lighten(#9FB8EB, 10)
  --blue-l2-color: lighten(#9FB8EB, 20)
  --blue-dark-color: #4F79D0
  --blue-d1-color: darken(#9FB8EB, 10)
  --blue-d2-color: darken(#9FB8EB, 20)

  --lc-border-color: var(--color-primary-dark)
  --lc-point-background-color: var(--white-color)
  --lc-point-border-color: #4F79D0
  --lc-point-hover-background-color: var(--white-color)
  --lc-background-color: var(--color-white)
  --lc-tooltip-border-color: var(--color-neutral-400)
  --lc-tooltip-title-font-color: var(--color-neutral-700)
  --lc-tooltip-body-font-color: var(--color-neutral-500)
  --lc-tooltip-background-color: var(--white-color)


  --yellow-color: var(--color-warning)
  --white-color: #FFFFFF
  --black-color: #121010
  --gray-color: #F7F8FC
  --gray1-color: #707070
  --gray2-color: #DFE0EB
  --gray3-color #E2E2E6
  --gray4-color #D0CFCF
  --red1-color: #F45B69
  --red2-color: #FDDEE1
  --purple-color: #6E44FF
  --warning-color: #FFEDC2
  --light-blue-color: #CAE6FF
  --green-color: var(--color-success)
  --green1-color: lighten(#52CB50, 20)
  --green2-color: var(--color-success-light)
  --green3-color: var(--color-success-dark)
  --yellow1-color: var(--color-warning)
  --blue1-color: var(--color-information)
  --blue-color-link: var(--blue-dark-color)


  /* Application colors */
  --brand-color: var(--color-primary)
  --brand-hover-color: var(--color-primary-dark)
  --brand-focus-color: var(--color-primary-dark)
  --brand-light-color: var(--color-primary-light)
  --brand-hover-light-color: var(--color-primary-light);
  --brand-dark-color var(--color-primary-dark)
  --brand-l1-color: var(--blue-l1-color)
  --brand-l2-color: var(--blue-l2-color)
  --brand-d1-color: var(--blue-d1-color)
  --brand-d2-color: var(--blue-d2-color)
  --border-color: var(--gray2-color)

  --bg-primary-color: var(--gray-color)
  --bg-secondary-color: var(--white-color)
  --bg-fail-color: var(--color-danger)
  --bg-info-color: var(--color-information)
  --bg-warning-color: var(--color-warning)
  --bg-success-color: var(--color-success)

  --fg-primary-color: var(--black-color)
  --fg-secondary-color: var(--gray1-color)
  --fg-disabled-color: var(--gray2-color)
  --fg-link-color: var(--color-primary-dark)

  --primary-button-color: var(--white-color)

  --chart-body-color: var(--color-primary)

  /* Font */
  --font-family: Inter, sans-serif
  --fw-bold: 700
  --fw-semi-bold: 600
  --fw-medium: 500
  --fw-regular: 400

  --fs-decor-title: 28px
  --fs-display: 25px
  --fs-h1: 22px
  --fs-h2: 18px
  --fs-h3: 15px
  --fs-h4: 15px
  --fs-gh: 13px
  --fs-xs: 12px

  --lh-decor-title: 36px
  --lh-display: 32px
  --lh-h1: 28px
  --lh-h2: 24px
  --lh-h3: 20px
  --lh-h4: 24px
  --lh-gh: 20px
  --lh-xs: 16px

  base-module = 0.4rem
  --layout-padding-left base-module * 8
  --layout-padding-right base-module * 6
  --layout-negative-margin-left base-module * 8 * (-1)
  --layout-negative-margin-right base-module * 6 * (-1)

  .layout-container
    padding-left var(--layout-padding-left)
    padding-right var(--layout-padding-right)
    &__negative-margin
      margin-left var(--layout-negative-margin-left)
      margin-right var(--layout-negative-margin-right)

.container
  margin 0 !important
.row
  margin-left 0 !important
  margin-right 0 !important
.negative-x-margin
  margin-right -12px !important
  margin-left -12px !important



.preloader
  &:before
    background url("/assets/aisno-spinner-small.svg") center no-repeat

.cluster
    div
      outline none

.no-data-area
  &__image
    width 334px
    height 280px
    background url("./assets/no_analytics.svg") 100% 100% no-repeat
    background-size contain
    background-position center

.tax-versions-bar .mat-tab-links .mat-tab-link
  margin-right 3.2rem

.go-back-link
  padding 1.6rem 0 0 0
  a
    display flex
    align-items center
    color: var(--color-primary-dark)
    font-size 1.3rem
    line-height: 1.8rem
    letter-spacing 0.2px
    cursor pointer
    text-decoration none
    span
      padding-left 1rem

.risk-circle-mark
  display block
  width 0.8rem
  height 0.8rem
  border-radius 50%
  &_low
    background-color var(--green1-color)
  &_medium
    background-color var(--yellow1-color)
  &_high
    background-color var(--red1-color)
  &_unset
    background-color var(--gray1-color)
