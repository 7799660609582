/* KKM Table */
.kkm-table
  table-layout auto

  .mat-row,
  .mat-header-row
    border none

  .mat-row
    background-color var(--white-color)

    &.disabled
      background-color var(--bg-primary-color)

      .mat-cell
        color var(--fg-secondary-color)

  .mat-header-row
    align-items stretch
    min-height 5.2rem
    box-shadow 0 0 2px rgba(0, 0, 0, 0.15)

  .mat-cell,
  .mat-footer-cell
    border-bottom: 1px solid var(--border-color)

  .mat-cell
    @extend .body_text

  &.with-hover
    .mat-row:hover
      background-color var(--brand-hover-light-color)

  &.responsive-cells
    .mat-row
      align-items stretch
    .mat-cell
      height auto

  .mat-header-cell
    @extend .grid_header
    background-color var(--bg-primary-color)
    border-bottom: none;

    &.mat-sort-header
      .mat-sort-header-container
        //justify-content space-between
        flex-grow: 1

        .mat-sort-header-arrow
          margin 0 2.4rem 0 0.8rem

          .mat-sort-header-stem,
          .mat-sort-header-indicator .mat-sort-header-pointer-left,
          .mat-sort-header-indicator .mat-sort-header-pointer-right,
          .mat-sort-header-indicator .mat-sort-header-pointer-middle
            background none

          &:after
            display block
            content ""
            width 1.4rem
            height 1.4rem
            background url("/assets/sort.svg") center no-repeat
            transform rotate(180deg)
            background-size 1.4rem
            color var(--gray2-color)

      &[aria-sort='descending'] //&[aria-sort='ascending']

        .mat-sort-header-arrow:after
          transform rotate(0deg)

  tr.mat-header-row
  .mat-header-row
    min-height 5.2rem
    height auto

  .mat-header-cell
  .mat-cell,
  .mat-footer-cell
    min-height 5.2rem
    padding 1.6rem 0.8rem 1.6rem 2.4rem

    a
      font var(--fw-regular) var(--fs-h3) var(--font-family)

.mat-sort-header-end
  .mat-sort-header-container
    flex-grow 0 !important
  .mat-sort-header-arrow
    margin-right 0 !important
