.kkm-mat-menu+*.cdk-overlay-connected-position-bounding-box .cdk-overlay-pane
  transform translate(0, 1rem) !important
  .mat-menu-panel
    min-width 13rem
  .mat-menu-content
    padding 0 !important
    margin-top 1rem
    border 1px solid var(--gray2-color)
    button
      display flex
      align-items center
      background-color var(--white-color)
      padding-left 1rem
      font-weight var(--fw-regular)
      font-size var(--fs-h4)
      line-height  var(--lh-h4)
      font-family var(--font-family)
      &:hover
        background-color var(--brand-hover-light-color)
      .mat-icon
        margin-right 1rem
