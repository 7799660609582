.kkm-dialog_panel

  .mat-dialog-container
    position relative
    display block
    width 100%
    height 100%
    padding 2.4rem 0
    box-sizing border-box
    outline 0
    box-shadow -1px 2px 9px rgba(0, 0, 0, 0.23)
    background var(--white-color)
    border-radius 0
    color var(--fg-primary-color)
    cursor default
    overflow hidden

    .mat-dialog-title
      padding 0 3.2rem
      font-weight var(--fw-semi-bold)
      font-size var(--fs-h1)
      line-height  var(--lh-h1)
      font-family var(--font-family)
      color var(--fg-primary-color)
      margin-bottom 0

    .mat-dialog-close-button
      position absolute
      top 0
      right 0
      padding 2.4rem 3.2rem 1.6rem 1.6rem
      background none
      border none
      cursor pointer
      outline none

      .mat-icon
        width 2.4rem !important
        height 2.4rem !important
      &.medium
        .mat-icon
          width 2rem !important
          height 2rem !important

    .mat-dialog-content
      margin 0
      padding 2.8rem 3.2rem 0

    .mat-dialog-actions
      padding 0 3.2rem 2.4rem

      button, a
        &:not(:last-child)
          margin-right 2.4rem
          margin-left 0

      a
        text-decoration underline



  @media screen and (max-width 375px)
    height 100vh
    width 100vw

    --title-height: 6rem

    .mat-dialog-container
      position relative
      padding 0
      overflow-y auto

      .mat-dialog-title
        font-weight var(--fw-bold) !important
        font-size var(--fs-h3) !important
        line-height  var(--title-height) !important
        font-family var(--font-family) !important
        color var(--fg-primary-color)
        height var(--title-height)
        min-height var(--title-height)
        padding 0 1.6rem
        padding-right 5rem
        border-bottom 1px solid var(--gray2-color)
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
        margin-bottom 0

        &.centered
          text-align center
          padding-right 0

      .mat-dialog-close-button
        padding 2rem 2rem 1.6rem 1.6rem

        &.back-button
          left 0

      .mat-dialog-content
        margin 0
        padding 2.4rem 1.6rem
        max-height none

      .mat-dialog-actions
        padding 0 1.6rem 3rem

        button, a
          &:not(:last-child)
            margin-right 0.5rem

  @media screen and (max-width 768px)
    
    margin-top 0 !important
    margin-right 0 !important

.cdk-overlay-backdrop
  opacity 0 !important

.cdk-global-overlay-wrapper
  background rgba(0, 0, 0, 0.32)

.cdk-global-overlay-wrapper_transparent
  background transparent !important
