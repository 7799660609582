.mat-tab-nav-bar
  border-bottom: 1px solid rgba(0, 0, 0, 0.12)
  .mat-tab-links
    .mat-tab-label,
    .mat-tab-link
      @extend .body_text

    .mat-tab-link
      margin-right 5rem
      min-width 10px
      letter-spacing 0.2px
      font-size 1.3rem
      line-height 2.4rem
      font-weight 600
      text-transform uppercase
      color var(--gray1-color)
      height 4rem
      padding 0
      padding-bottom 1.3rem
      opacity 1

      &.mat-tab-label-active
        color var(--fg-primary-color)

  .mat-ink-bar
    background-color var(--brand-color) !important
    height 0.3rem !important

mat-tab-group.kkm-secondary-tabs
  .mat-tab-body-wrapper
    height 100%
  .mat-tab-label
    min-width 0
    padding 0
    opacity 1
    color var(--fg-secondary-color)
    font-size var(--fs-h3)
    font-weight var(--fw-semi-bold)
    &.mat-tab-label-active
      color var(--brand-color)
    &.mat-tab-disabled
      opacity .6
  .mat-tab-label:not(:last-child)
    margin-right 4.8rem
