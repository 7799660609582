:root
  // Base colors
  --color-white: #FFFFFF;
  --color-black: #111111;
  --color-background: #F7FBFC;

  // Primary colors
  --color-primary: #9FB8EB;
  --color-primary-light: #F2F6FF;
  --color-primary-dark: #4F79D0;

  // Accent colors
  --color-accent: #FEC50C;
  --color-accent-100: #FFF9E7;
  --color-accent-200: #FFF3CE;
  --color-accent-300: #FFE89E;
  --color-accent-400: #FED13D;
  --color-accent-500: --color-accent;
  --color-accent-600: #CB9E0A;
  --color-accent-700: #CB9E0A;
  --color-accent-800: #664F05;
  --color-accent-900: #332702;

  // Neutral colors
  --color-neutral: #59606A;
  --color-neutral-100: #F0F4F7;
  --color-neutral-200: #E1E8EF;
  --color-neutral-300: #C0C8D1;
  --color-neutral-400: #939AA4;
  --color-neutral-500: --color-neutral;
  --color-neutral-600: #474D55;
  --color-neutral-700: #353A40;
  --color-neutral-800: #24262A;
  --color-neutral-900: #1B1D20;

  // Danger colors
  --color-danger: #F54848;
  --color-danger-light: #FFE8E8;
  --color-danger-dark: #AF0808;

  // Success colors
  --color-success: #52CB50;
  --color-success-light: #E2FFD7;
  --color-success-dark: #146F29;

  // Warning colors
  --color-warning: #F3C10E;
  --color-warning-light: #FFF5C4;
  --color-warning-dark: #A47908;

  // Information colors
  --color-information: #3AA1EC;
  --color-information-light: #DEF1FF;
  --color-information-dark: #006EBE;

  // Status colors
  --color-status-1: #F2FFEE;
  --color-status-2: #C8FF66;
  --color-status-3: #FF573F;
